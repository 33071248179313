
export default function Carousel(container) {
    let blocked = false;
    this.container = container;
    let clone;
    let elements = [].map.call(container.querySelectorAll('li'), (el) => el);
    this.elementsCount = elements.length;
    this.current = Math.ceil(this.elementsCount / 2);

    this.next = function () {
        const currentEl = container.querySelector('li.current');
        elements[self.current].classList.add('current');
        currentEl.classList.remove('current');
        const previous = self.current;

        duplicateFirstElement();
        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                recalcPositions(previous, 1);
            });
        });
        blocked = true;
        setTimeout(() => {
            removeClone();
            blocked = false;
        }, 300);
    };
    this.prev = function () {
        const currentEl = container.querySelector('li.current');
        elements[self.current - 2].classList.add('current');
        currentEl.classList.remove('current');
        const previous = self.current;

        duplicateLastElement();
        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                recalcPositions(previous, -1);
            });
        });
        blocked = true;
        setTimeout(() => {
            removeClone();
            blocked = false;
        }, 300);
    };

    var self = this;

    var recalcPositions = function (from, direction) {
        elements.concat([clone]).forEach((element) => {
            element.style.setProperty('--offset', element.style.getPropertyValue('--offset') * 1 - direction);
        });
    };
    var removeClone = function () {
        clone.parent.style.setProperty('--offset', clone.style.getPropertyValue('--offset') * 1);
        clone.parentElement.replaceChild(clone.parent, clone);

        elements = [].map.call(container.querySelectorAll('li'), (el) => el);
    };

    var duplicateLastElement = function () {
        clone = elements[elements.length - 1].cloneNode(true);
        clone.style.setProperty('--offset', -2);
        clone.classList.remove('curent');
        clone.parent = elements[elements.length - 1];
        self.container.prepend(clone);
    };
    var duplicateFirstElement = function () {
        clone = elements[0].cloneNode(true);
        clone.style.setProperty('--offset', 2);
        clone.classList.remove('curent');
        clone.parent = elements[0];
        self.container.append(clone);
    };

    elements.forEach((element, i) => {
        element.style.setProperty('--offset', i - self.current + 1);
        element.querySelector('.prev').addEventListener('click', (e) => {
            e.preventDefault();
            if (!blocked) {
                self.prev();
            }
        });
        element.querySelector('.next').addEventListener('click', (e) => {
            e.preventDefault();
            if (!blocked) {
                self.next();
            }
        });
    });
}