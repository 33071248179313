import Carousel from 'pages/referral-program/refer-a-friend/carousel.src';

[].forEach.call(document.querySelectorAll('.carousel'), (carousel) => {
    const c = new Carousel(carousel);
});

[...document.querySelectorAll('.toggle-details-on')].forEach((trigger) => {
    trigger.addEventListener('click', (e) => {
        e.preventDefault();
        [...document.querySelectorAll('.bottom')].filter((packetEl) => packetEl.contains(e.target))[0].classList.add('details-visible');
    });
});
[...document.querySelectorAll('.toggle-details-off')].forEach((trigger) => {
    trigger.addEventListener('click', (e) => {
        e.preventDefault();
        [...document.querySelectorAll('.bottom')].filter((packetEl) => packetEl.contains(e.target))[0].classList.remove('details-visible');
    });
});
